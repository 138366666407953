
import { defineComponent, onMounted, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";

import DaftarProduct from "@/components/1crm/product/daftar-product/daftar-product.vue";

// import Legalitas from "@/components/1crm/legal/legalitas/superadmin/legalitas.vue";
// import UploadComponent from "@/components/1crm/legal/legalitas/superadmin/upload-modal.vue";

export default defineComponent({
  name: "kt-widget-11",
  components: {
    DaftarProduct,
    // Legalitas,
    // UploadComponent,
  },
  props: {
    widgetClasses: String,
  },

  beforeMount() {
    store.dispatch(Actions.VERIFY_AUTH);

    setCurrentPageBreadcrumbs("Daftar Product", ["Data"]);
  },
});
